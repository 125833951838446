import { validateEmail, validateNoSpaces, validatePhone } from '../../core/utils/validators.js'

document.addEventListener('DOMContentLoaded', function () {
  window.Parsley.addValidator('phoneonlynumbers', {
    requirementType: 'string',
    validateNumber: value => validatePhone(value),
    priority: 32,
    messages: {
      es: 'Formato de número de teléfono incorrecto',
      en: 'Incorrect telephone number format',
      de: 'Das Format der eingegebenen Telefonnummer ist ungültig',
      fr: 'Format de numéro de téléphone incorrect',
      it: 'Formato del numero di telefono errato',
      pt: 'Formato do número de telefone incorreto',
      ru: 'Неправильный формат номера телефона',
    },
  })

  window.Parsley.addValidator('allemails', function (value) {
    const results = value.split(';').map(validateEmail)
    return results.every(element => element === true)
  })
  window.Parsley.addMessage('es', 'allemails', 'Separa cada email por ; sin espacios')
  window.Parsley.addMessage('en', 'allemails', 'Separa cada email por ; sin espacios')

  window.Parsley.addValidator('nospaces', {
    requirementType: 'string',
    validateString: value => validateNoSpaces(value),
    messages: {
      es: 'Este campo no permite espacios',
      en: 'This field does not permit spaces',
      de: 'In diesem Feld sind keine Leerzeichen erlaubt',
      fr: "Ce champ n'autorise pas d’espaces",
      it: 'Questo campo non ammette spazi',
      pt: 'Este campo não permite espaços',
      ru: 'В этом поле не допускаются пробелы',
    },
  })
})
