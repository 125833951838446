import { sendUtagEvent } from '../../../src/core/analytics/utag_events'
import { getCleanedString } from '../../../src/utils/utilities'

export function initializeUtagMiceEvents() {
  function getOnlyLetters(str) {
    let newStr = getCleanedString(str)
    newStr = newStr.replace(/[^a-zA-Z]/g, '')
    return newStr
  }
  const day = document.getElementById('date_day').value
  const month = document.getElementById('date_month').value
  const year = document.getElementById('date_year').value
  let date = ''
  if (day !== '' && month !== '' && year !== '') {
    date = `${day}/${month}/${year}`
  }
  const servicesElement = document.getElementById('services')
  const selectedOptions = Array.from(servicesElement.selectedOptions)
  const selectedServices =
    selectedOptions.length > 0 ? selectedOptions.map(option => option.value.replace(/\s+/g, '-')).join('_') : ''

  const fields = [
    { label: document.getElementById('type_event').value },
    { label: document.getElementById('applicant').value },
    { label: selectedServices },
    { label: getOnlyLetters(document.getElementById('country').value) },
    { label: document.getElementById('hear_about_us').value },
    { label: date },
    { label: document.getElementById('preferred_destination').value },
    { label: document.getElementById('number_guests').value },
    { label: document.getElementById('number_rooms').value },
  ]

  const act = 'toe-tor-spa-cou-hhu-ied-des-ngu-roo'
  const label = []

  fields
    .filter(field => field.label !== '')
    .forEach(field => {
      label.push(field.label)
    })

  sendUtagEvent({
    data: {
      event_name: 'quote_mice',
      event_cat: 'rfp',
      event_act: act,
      event_lbl: label.join('-'),
      event_purpose: 'booking',
      event_structure: 'impression',
    },
  })
}
