import { currentDevice } from '../../core/utils/currentDevice'
import { readCookie, setCookie } from '../../utils/cookie_manager'

const adjustmentDevice = currentDevice.isMobile ? -265 : 10

$(document).on('click', '.language-selector .language-selector__current', function (e) {
  e.preventDefault()
  const $target = $(this)

  calculateChildrenPosition($target)
})

$(document).on('click', '.language-selector li a', function () {
  var locale = $(this).data('locale')
  sessionStorage.removeItem('fastbooking_data')
  setCookie('update_utag_data', true)
  if (locale !== undefined) {
    setCookie('locale', locale)
    IB.cookieManager.deleteCookies(['language'])
    setCookie('language', locale, -1)
  }
})

$(document).on('click', '.language-elements li.language', function () {
  IB.cookieManager.deleteCookies(['language'])
  setCookie('language', "", -1)
})

function calculateChildrenPosition($target) {
  const $submenuWrapper = $target.closest('.language-selector').find('.drop-down-links')
  const menuItemPos = $target.position()

  if (!$submenuWrapper.is(':visible')) {
    let styles = {
      top: menuItemPos.top + $target.outerHeight() + adjustmentDevice,
      left: menuItemPos.left,
    }
    // If inside reb main nav, it should slide top
    if ($target.closest('.reb-main-nav__language-selector').length) {
      styles = {
        ...styles,
        transform: `translateY(calc(-100% - ${$target.closest('.language-selector').outerHeight()}px - 16px))`,
      }

      $submenuWrapper.addClass('to-top')
    }

    $submenuWrapper.css(styles)

    $submenuWrapper.slideToggle('fast')
  } else {
    $submenuWrapper.hide()
  }
}

$(function () {
  const cookieLocale = readCookie('locale')
  const languajeSelectorLinks = document.querySelector('ul.js-language-selector-links li.active a')
  const selectorLocale = languajeSelectorLinks !== null ? languajeSelectorLinks.getAttribute('data-locale') : null
  if (selectorLocale !== null && cookieLocale != selectorLocale) {
    sessionStorage.removeItem('fastbooking_data')
    setCookie('locale', selectorLocale)
    ajax_utag_callback()
  }

  const footer_locale_selector = document.querySelector('.footer-contact .language-selector')
  if (footer_locale_selector !== null) {
    const header_locale_selector = IB.is_in_mice
      ? document.querySelector('.mice-header .language-selector')
      : document.querySelector('.main-header-content .language-selector')
    if (header_locale_selector != null) {
      const clone_select = header_locale_selector.cloneNode(true)
      clone_select.classList.remove('not-hide-on-tablet')
      footer_locale_selector.replaceWith(clone_select)
    }
  }
})
