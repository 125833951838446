import { PasswordStrength } from './password_strength';

window.Parsley.addValidator(
  'passwordlengthpro',
  function (value, requirement) {
    if (value.length < 9) {return false;} else {return true;}
  },
  32
);

window.Parsley.addValidator(
  'passwordlengthweb',
  function (value, requirement) {
    if (value.length < 8) {return false;} else {return true;}
  },
  32
);

window.Parsley.addValidator(
  'passwordhasuppercase',
  function (value, requirement) {
    let username = $('#email');
    if (username.length == 0) {username = $('#iberostar_api_user_email');}
    username = username.val();

    let strength = PasswordStrength.test(username, value);
    if (strength.scoreFor('uppercase') < 1) {return false;} else {return true;}
  },
  32
);

window.Parsley.addValidator(
  'passwordhaslowercase',
  function (value, requirement) {
    let username = $('#email');
    if (username.length == 0) {username = $('#iberostar_api_user_email');}
    username = username.val();
    let strength = PasswordStrength.test(username, value);
    if (strength.scoreFor('lowercase') < 1) {return false;} else {return true;}
  },
  32
);


window.Parsley.addValidator(
  'passwordhasnumbers',
  function (value, requirement) {
    let username = $('#email');
    if (username.length == 0) {username = $('#iberostar_api_user_email');}
    username = username.val();
    let strength = PasswordStrength.test(username, value);
    if (strength.scoreFor('numbers_chars') < 1) {return false;} else {return true;}
  },
  32
);

window.Parsley.addValidator(
  'passwordincludeusername',
  function (value, requirement) {
    return validateUserName(value);
  },
  32
);

window.Parsley.addValidator(
  'passwordincludeusernameiberostarpro',
  function (value, requirement) {
    return validateUserName(value);
  },
  32
);

function validateUserName(value) {
  let username = $('.username-to-check-in-password-field').val();
  /* Check if username is empty or not */
  if (username !== undefined && username.length > 0) {
    let strength = PasswordStrength.test(username.toLowerCase(), value.toLowerCase());
    if (strength.scoreFor('username') < 0) {return false;} else {return true;}
  } else {
    return true;
  }
}
window.Parsley.addValidator(
  'passwordincludeemail',
  function (value, requirement) {
    let username = $('#email');
    if (username.length == 0) {username = $('#iberostar_api_user_email');}
    username = username.val();
    /* Check if email is nor empty*/
    if (username !== undefined && username.length > 0) {
      let strength = PasswordStrength.test(username, value);
      if (strength.scoreFor('username') < 0) {return false;} else {return true;}
    } else {
      return true;
    }
  },
  32
);

window.Parsley.addValidator(
  'passwordincludeemailname',
  function (value, requirement) {
    let username_before = $('#email');
    if (username_before.length == 0) {username_before = $('#iberostar_api_user_email');}
    username_before = username_before.val();
    /* Check if email is nor empty*/
    if (username_before !== undefined && username_before.length > 0) {
      username_before = username_before.split('@')[0];
      let strength_before = PasswordStrength.test(username_before, value);
      if (strength_before.scoreFor('username') < 0) {return false;} else {return true;}
    } else {
      return true;
    }
  },
  32
);


window.Parsley.addMessage('es', 'passwordlengthpro', 'Longitud debe ser mayor de 8 caracteres');
window.Parsley.addMessage('es', 'passwordlengthweb', 'Debe tener 8 caracteres o más');
window.Parsley.addMessage('es', 'passwordhasuppercase', 'La password debe contener mayúsculas');
window.Parsley.addMessage('es', 'passwordhaslowercase', 'La password debe contener minúsculas');
window.Parsley.addMessage('es', 'passwordhasnumbers', 'La password debe contener números');
window.Parsley.addMessage('es', 'passwordincludeusername', 'No puede contener el nombre del usuario');
window.Parsley.addMessage('es', 'passwordincludeemail', 'La password no puede contener el email del usuario');
window.Parsley.addMessage('es', 'passwordincludeemailname', 'La password no puede contener la parte anterior a la @');


window.Parsley.addMessage('fr', 'passwordlengthpro', 'La longueur doit être supérieure à 8 caractères');
window.Parsley.addMessage('fr', 'passwordlengthweb', 'Doit contenir 8 caractères ou plus');
window.Parsley.addMessage('fr', 'passwordhasuppercase', 'Le mot de passe doit contenir en majuscules');
window.Parsley.addMessage('fr', 'passwordhaslowercase', 'Le mot de passe doit contenir des lettres minuscules');
window.Parsley.addMessage('fr', 'passwordhasnumbers', 'Le mot de passe doit contenir les numéros');
window.Parsley.addMessage('fr', 'passwordincludeusername', 'Ne peut pas contenir le nom d utilisateur');
window.Parsley.addMessage('fr', 'passwordincludeemail', 'Le mot de passe ne doit pas être votre adresse e-mail');
window.Parsley.addMessage('fr', 'passwordincludeemailname', 'Le mot de passe ne peut pas contenir de caractères avant l’arrobase');

window.Parsley.addMessage('de', 'passwordlengthpro', 'Länge muss größer sein als 8 Zeichen');
window.Parsley.addMessage('de', 'passwordlengthweb', 'Muss mindestens 8 Zeichen haben');
window.Parsley.addMessage('de', 'passwordhasuppercase', 'Das Passwort muss in Großbuchstaben enthalten');
window.Parsley.addMessage('de', 'passwordhasnumbers', 'Das Passwort muss Zahlen enthalten');
window.Parsley.addMessage('de', 'passwordhaslowercase', 'Das Passwort muss Kleinbuchstaben enthalten');
window.Parsley.addMessage('de', 'passwordincludeusername', 'Darf nicht den Namen des Benutzername');
window.Parsley.addMessage('de', 'passwordincludeemail', 'Die E-Mail-Adresse darf im Passwort nicht enthalten sein');
window.Parsley.addMessage('de', 'passwordincludeemailname', 'Das Passwort darf nicht Teile vor dem At-Zeichen enthalten');

window.Parsley.addMessage('en', 'passwordlengthpro', 'Length must be greater than 8 characters');
window.Parsley.addMessage('en', 'passwordlengthweb', 'It must contain 8 characters or more');
window.Parsley.addMessage('en', 'passwordhasuppercase', 'The password must contain uppercase letters');
window.Parsley.addMessage('en', 'passwordhasnumbers', 'The password must contain numbers');
window.Parsley.addMessage('en', 'passwordhaslowercase', 'The password must contain lowercase letters');
window.Parsley.addMessage('en', 'passwordincludeusername', 'Username cannot be included');
window.Parsley.addMessage('en', 'passwordincludeemail', 'The password should not contain the email address');
window.Parsley.addMessage('en', 'passwordincludeemailname', 'The password should not contain the part appearing before the @ symbol');

window.Parsley.addMessage('it', 'passwordlengthpro', 'Lunghezza deve essere maggiore di 8 caratteri');
window.Parsley.addMessage('it', 'passwordlengthweb', 'Deve avere almeno 8 caratteri');
window.Parsley.addMessage('it', 'passwordhasuppercase', 'La password deve contenere lettere maiuscole');
window.Parsley.addMessage('it', 'passwordhasnumbers', 'La password deve contenere i numeri');
window.Parsley.addMessage('it', 'passwordhaslowercase', 'La password deve contenere minuscole');
window.Parsley.addMessage('it', 'passwordincludeusername', 'Non può includere il nome utente');
window.Parsley.addMessage('it', 'passwordincludeemail', 'La password non deve contenere l’e-mail');
window.Parsley.addMessage('it', 'passwordincludeemailname', 'La password non deve contenere la parte che precede la chiocciola');

window.Parsley.addMessage('pt', 'passwordlengthpro', 'Comprimento deve ser maior do que 8 caracteres');
window.Parsley.addMessage('pt', 'passwordlengthweb', 'Deve ter 8 carateres ou mais');
window.Parsley.addMessage('pt', 'passwordhasuppercase', 'A senha deve conter letras maiúsculas');
window.Parsley.addMessage('pt', 'passwordhasnumbers', 'A senha deve conter números');
window.Parsley.addMessage('pt', 'passwordhaslowercase', 'A palavra-passe deve conter minúsculas');
window.Parsley.addMessage('pt', 'passwordincludeusername', 'Não pode conter o nome de utilizador');
window.Parsley.addMessage('pt', 'passwordincludeemail', 'A palavra-passe não deve conter o email');
window.Parsley.addMessage('pt', 'passwordincludeemailname', 'A palavra-passe não deve conter nada antes de arroba');

window.Parsley.addMessage('ru', 'passwordlengthpro', 'Длина должна быть больше, чем 8 символов');
window.Parsley.addMessage('ru', 'passwordlengthweb', 'Длина должна быть не менее 8 знаков');
window.Parsley.addMessage('ru', 'passwordhasuppercase', 'Пароль должен содержать заглавные буквы');
window.Parsley.addMessage('ru', 'passwordhasnumbers', 'Пароль должен содержать цифры');
window.Parsley.addMessage('ru', 'passwordhaslowercase', 'Пароль должен содержать строчные буквы');
window.Parsley.addMessage('ru', 'parsley-passwordincludeusername', 'Не может содержать имя пользователя');
window.Parsley.addMessage('ru', 'passwordincludeemail', 'Пароль не должен содержать адрес электронной почты');
window.Parsley.addMessage('ru', 'passwordincludeemailname', 'Пароль не должен содержать начало адреса электронной почты');
