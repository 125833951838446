import Modal from '../../../shared/modal/modal'

function adminBrowsingIsValidElem(el) {
  const adminBrowsingIgnoreElems = ['input[type="hidden"]']
  return adminBrowsingIgnoreElems.every(function (value) {
    return !(el.matches(value) || el.classList.contains(value))
  })
}

function adminBrowsingGetElem(el) {
  let newEl = el.nextElementSibling
  while (newEl && !adminBrowsingIsValidElem(newEl)) {
    newEl = newEl.nextElementSibling
  }
  return newEl
}

function adminBrowsingBtnPosition() {
  document.querySelectorAll('.admin-browsing').forEach(function (el) {
    const blockElement = adminBrowsingGetElem(el)
    let elTop = blockElement.getBoundingClientRect().top + window.scrollY
    const elLeft = blockElement.getBoundingClientRect().left + blockElement.offsetWidth
    const id = el.getAttribute('id')

    document.querySelectorAll(`*[data-admin-id="${id}"]`).forEach(function (adminEl) {
      adminEl.style.top = `${elTop}px`
      adminEl.style.left = `${elLeft}px`
      elTop += 60
    })
  })
}

function adminBrowsingImageParse(desktop_url, mobile_url, pattern) {
  const desktop_matches = desktop_url.match(pattern)
  const image = {
    image_url: { desktop: desktop_matches.input },
    edit_url: { desktop: `/admin/images/${desktop_matches[1]}/crops/ratio/${desktop_matches[2]}/edit` },
    metadata_url: {
      desktop: `/admin/images/${desktop_matches[1]}/crops/ratio/${desktop_matches[2]}/${desktop_matches[3]}/metadata`,
    },
    crop_ratio: { desktop: `${desktop_matches[2]}[${desktop_matches[3]}]` },
  }

  if (typeof mobile_url !== 'undefined' && mobile_url !== '') {
    const mobile_matches = mobile_url.match(pattern)
    image.image_url.mobile = mobile_matches.input
    image.edit_url.mobile = `/admin/images/${mobile_matches[1]}/crops/ratio/${mobile_matches[2]}/edit`
    image.metadata_url.mobile = `/admin/images/${mobile_matches[1]}/crops/ratio/${mobile_matches[2]}/${mobile_matches[3]}/metadata`
  }

  return image
}

function openModalAdminBrowser(htmlString) {
  const btnAdminBrowsing = document.querySelector('.js-admin-browsing-btn')
  btnAdminBrowsing.setAttribute('data-admin-browsing-modal-trigger', 'modal-admin-browsing-id')
  Modal.showHtml({
    openTrigger: 'data-admin-browsing-modal-trigger',
    html: htmlString,
    onShow: () => {
      ScrollLock.on()
    },
    onClose: () => {
      ScrollLock.off()
    },
    awaitCloseAnimation: true,
  })
}

function adminBrowsingBtnClick(element) {
  const el = element
  const block = document.querySelector(`#${el.dataset.adminId}`).nextElementSibling
  const editBlockUrl = el.dataset.adminLink
  const editBlockTitle = el.dataset.title
  const imagePattern = new RegExp('/uploads/image/(\\d+)/crops/([\\d|:]+)/(\\d+)')
  const images = []
  let lastImage = ''

  block.querySelectorAll('*').forEach(function (child) {
    const src = child.getAttribute('src')
    const srcset = child.getAttribute('srcset')
    const bg = child.style.backgroundImage.replace('url(', '').replace(')', '').replace(/\"/gi, '')
    const mobile_url = child.dataset.mobile
    const substrings = srcset !== null ? srcset.split(',') : []
    if (imagePattern.test(src)) {
      images.push(adminBrowsingImageParse(src, mobile_url, imagePattern))
    }

    if (imagePattern.test(bg)) {
      images.push(adminBrowsingImageParse(bg, mobile_url, imagePattern))
    }

    if (imagePattern.test(srcset)) {
      const image = srcset
      for (var i = 0; i < substrings.length; i++) {
        if (imagePattern.test(substrings[i].trim())) {
          const image = substrings[i].trim().replace(/ \d+w/g, '')
          if (image !== lastImage) {
            images.push(adminBrowsingImageParse(image, mobile_url, imagePattern))
          }
          lastImage = image
        }
      }
    }
  })

  const htmlLightbox = document.createElement('div')
  htmlLightbox.className = 'admin-browsing-container'
  htmlLightbox.setAttribute('id', 'modal-admin-browsing-id')

  const editBlockButton = document.createElement('a')
  editBlockButton.className = 'btn-primary'
  editBlockButton.target = '_blank'
  editBlockButton.href = editBlockUrl
  editBlockButton.innerHTML = editBlockTitle
  htmlLightbox.appendChild(editBlockButton)

  images.forEach(function (image) {
    let imageContainer = document.createElement('div')
    imageContainer.className = 'cols-2'
    let imageDiv = document.createElement('div')
    imageDiv.className = 'col'
    let linkDiv = document.createElement('div')
    linkDiv.className = 'col'
    imageContainer.appendChild(imageDiv)
    imageContainer.appendChild(linkDiv)

    let img = document.createElement('img')
    img.srcset = image.image_url.desktop
    img.className = 'block-image'
    imageDiv.appendChild(img)

    let editLink = document.createElement('a')
    editLink.className = 'btn btn-primary'
    editLink.target = '_blank'
    editLink.href = image.edit_url.desktop
    editLink.innerHTML =
      typeof image.image_url.mobile === 'undefined'
        ? `Edit image crop ${image.crop_ratio.desktop}`
        : `Edit image crop (Desktop) ${image.crop_ratio.desktop}`
    linkDiv.appendChild(editLink)

    let metadataLink = document.createElement('a')
    metadataLink.className = 'btn btn-primary'
    metadataLink.target = '_blank'
    metadataLink.href = image.metadata_url.desktop
    metadataLink.innerHTML = 'View Akamai metadata'
    linkDiv.appendChild(metadataLink)

    htmlLightbox.appendChild(imageContainer)

    if (typeof image.image_url.mobile !== 'undefined') {
      imageContainer = document.createElement('div')
      imageContainer.className = 'cols-2'
      imageDiv = document.createElement('div')
      imageDiv.className = 'col'
      linkDiv = document.createElement('div')
      linkDiv.className = 'col'
      imageContainer.appendChild(imageDiv)
      imageContainer.appendChild(linkDiv)

      img = document.createElement('img')
      img.srcset = image.image_url.mobile
      img.className = 'block-image'
      imageDiv.appendChild(img)

      editLink = document.createElement('a')
      editLink.className = 'btn-primary'
      editLink.target = '_blank'
      editLink.href = image.edit_url.mobile
      editLink.innerHTML = 'Edit image crop (Mobile)'
      linkDiv.appendChild(editLink)

      metadataLink = document.createElement('a')
      metadataLink.className = 'btn btn-primary'
      metadataLink.target = '_blank'
      metadataLink.href = image.metadata_url.mobile
      metadataLink.innerHTML = 'View Akamai metadata'
      linkDiv.appendChild(metadataLink)

      htmlLightbox.appendChild(imageContainer)
    }
  })
  const htmlString = htmlLightbox.outerHTML
  openModalAdminBrowser(htmlString)
}

function adminBrowsingBtnDblClick(element) {
  const el = element
  const blockId = el.dataset.adminId.split('-').pop()
  const blockSlotId = el.dataset.adminLink.split('-').pop()
  const url = el.dataset.adminLink.replace('/edit/', `/block_slots/${blockSlotId}/blocks/${blockId}/quick_edit/`)
  window.open(url)
}

function init() {
  if (document.querySelector('.admin-browsing')) {
    window.IB.adminBrowsing.adminBrowsingBtnPosition()
  }
}

window.IB = window.IB || {}
window.IB.adminBrowsing = {
  init,
  adminBrowsingBtnPosition,
  adminBrowsingBtnClick,
  adminBrowsingBtnDblClick,
}

window.addEventListener('load', function () {
  if (document.querySelector('.admin-browsing')) {
    import('./admin_browsing.scss')

    window.IB.adminBrowsing.init()

    let clickCount = 0
    let singleClickTimer = null
    document.addEventListener('click', function (e) {
      if (e.target.matches('*[data-admin-id]')) {
        e.preventDefault()
        const element = e.target
        clickCount++
        if (clickCount === 1) {
          singleClickTimer = setTimeout(function () {
            clickCount = 0
            window.IB.adminBrowsing.adminBrowsingBtnClick(element)
          }, 220)
        } else if (clickCount === 2) {
          clearTimeout(singleClickTimer)
          clickCount = 0
          window.IB.adminBrowsing.adminBrowsingBtnDblClick(element)
        }
      }
    })

    window.addEventListener('resize', function () {
      window.IB.adminBrowsing.adminBrowsingBtnPosition()
    })
  }
})
