import { initializeUtagMiceEvents } from '../../blocks/mice_form/mice.analytics'
import { sendUtagEvent } from '../../core/analytics/utag_events'
import { getCleanedString } from '../../utils/utilities'

const isStaMicrosite = document.querySelector('html').dataset.inMicrosite === 'star_agents'
const purposeValue = isStaMicrosite ? 'loyalty' : 'information'

function field_form_event(form_type, form_name, form_action, field_name, event_name = '') {
  sendUtagEvent({
    data: {
      event_name,
      event_cat: form_type,
      event_act: `${form_action}-${form_name}`,
      event_lbl: getCleanedString(field_name),
      event_purpose: purposeValue,
      event_structure: 'body',
    },
  })
}

function error_form_event(form_name, form_action, field_name, form) {
  const form_type =
    form.dataset.formType === undefined || form.dataset.formType === 'rfp' ? 'form' : form.dataset.formType
  const formName = form.dataset.formType === 'rfp' ? 'form-rfp' : form_name

  sendUtagEvent({
    data: {
      event_name: 'error_form',
      event_cat: form_type,
      event_act: `${form_action}-${formName}`,
      event_lbl: getCleanedString(field_name),
      event_purpose: purposeValue,
      event_structure: 'body',
    },
  })
}

function start_form_event(form_name, form_action, field_name, form) {
  const form_is_sent = form.classList.contains('form-sent')
  const form_type =
    form.dataset.formType === undefined || form.dataset.formType === 'rfp' ? 'form' : form.dataset.formType
  const formName = form.dataset.formType === 'rfp' ? 'rfp' : form_name
  if (!form_is_sent) {
    const label = field_name.replace('_id', '').replace('iberostar_api_user', '').replace('vo_not_logged_booking', '')
    sendUtagEvent({
      data: {
        event_name: 'start_form',
        event_cat: form_type,
        event_act: `${form_action}-${formName}`,
        event_lbl: getCleanedString(label),
        event_purpose: purposeValue,
        event_structure: 'body',
      },
    })
  }
}

function normalizeFieldName(name) {
  return name.replace('[', '').replace(']', '').replace('iberostar_api_userfirst_name', 'first_name').replace('_', ' ')
}

function getFieldName(custom_field_name, name) {
  let field_name = ''
  if (custom_field_name !== undefined && custom_field_name !== '') {
    field_name = custom_field_name
  } else {
    field_name = name
  }
  return field_name
}

function isVisible(element) {
  return (
    element.offsetWidth > 0 ||
    element.offsetHeight > 0 ||
    getComputedStyle(element).display !== 'none' ||
    element.style.display !== 'none' ||
    element.style.visibility !== 'hidden'
  )
}

export function initializeUtagEvents() {
  /* utag-event for click in fasbooking destination input */
  document.addEventListener('click', event => {
    var target = event.target
    var validSelectors = [
      'form.new_iberostar_api_user_session button.btn-with-arrow',
      'form#new_iberostar_api_user button',
      '#form_validate button',
      '#form_wci button',
      '#new_vo_not_logged_booking button',
      '.utag-form .utag-form-button',
      'button.utag-wedding',
      'button.utag-mice',
      '#form_mro button',
      '.contact-us form button',
    ]

    if (validSelectors.some(selector => target.matches(selector))) {
      if (target.matches('.contact-us form button')) {
        field_form_event(
          'form',
          target.closest('form.utag-form').getAttribute('data-form-name'),
          'try send form',
          '',
          'try_send_form'
        )
      } else {
        field_form_event(
          'form',
          target.closest('form').getAttribute('data-form-name'),
          'try send form',
          '',
          'try_send_form'
        )
      }
    }
  })

  document.addEventListener('submit', event => {
    var target = event.target
    var validSelectors = [
      'form.new_iberostar_api_user_session',
      'form#new_iberostar_api_user_session',
      'form#new_iberostar_api_user',
      'form#personal-data ',
      '#form_validate',
      '#form_wci',
      '#new_vo_not_logged_booking',
      'form.utag-form',
      '.utag-form-button',
      '.utag-form-success',
      '#form_mro',
      '.contact-us form',
      '.mice-form form',
      'button.utag-mice',
    ]

    if (validSelectors.some(selector => target.matches(selector))) {
      if (target.matches('.contact-us form')) {
        field_form_event(
          'form',
          target.closest('form.utag-form').getAttribute('data-form-name'),
          'success form',
          '',
          'success_form'
        )
      } else if (target.matches('.mice-form form')) {
        field_form_event('success_form', 'form', 'success form', '', 'success_form')
        initializeUtagMiceEvents()
      } else {
        field_form_event('form', target.getAttribute('data-form-name'), 'success form', '', 'success_form')
      }
    }
  })

  document
    .querySelectorAll('form.mice-hidden-form, form.ibepro-utag-register, form.mice-form, form.staragent-utag-register')
    .forEach(function (form) {
      form.querySelectorAll('input, select, textarea').forEach(function (input) {
        if (input) {
          const parentForm = input.closest('form')
          const form_name = parentForm.dataset.formName
          const custom_field_name = input.dataset.utagNormalizeFieldName

          switch (input.type) {
            case 'select-multiple':
            case 'select-one':
              input.addEventListener('click', function () {
                start_form_event(
                  form_name,
                  'start form',
                  getFieldName(custom_field_name, normalizeFieldName(input.name)),
                  parentForm
                )
                parentForm.classList.add('form-sent')
              })
              break
            case 'text':
            case 'textarea':
            case 'checkbox':
            case 'radio':
            case 'email':
            case 'password':
              input.addEventListener('click', function () {
                start_form_event(
                  form_name,
                  'start form',
                  getFieldName(custom_field_name, normalizeFieldName(input.name)),
                  parentForm
                )
                parentForm.classList.add('form-sent')
              })
              break
          }
        }
      })
    })

  try {
    window.Parsley.on('form:validated', function (fieldInstance) {
      const field_error_name = []
      if (
        fieldInstance.element.classList.contains('utag-form') &&
        fieldInstance.element.classList.contains('validate-form')
      ) {
        document.querySelectorAll('.parsley-error, .parsley-error input').forEach(function (element) {
          if (
            !element.hidden ||
            (element.type === 'checkbox' && !element.nextElementSibling.classList.contains('ignore-utag-validation'))
          ) {
            let name
            if (element.dataset.utagError && element.dataset.utagError !== '') {
              name = element.dataset.utagError
            } else if (element.dataset.utagNormalizeFieldName && element.dataset.utagNormalizeFieldName !== '') {
              name = element.dataset.utagNormalizeFieldName
            } else {
              name = element.getAttribute('name')
            }

            if (name !== null && name !== undefined && name !== 'iberostar_api_user[accepts_privacy]') {
              field_error_name.push(
                name
                  .replace('agent_', '')
                  .replace('comercial_', '')
                  .replace('newslet', '')
                  .replace('vo_not_logged_booking', '')
                  .replace('booking_', '')
                  .replace('iberostar_api_user', '')
                  .replace('billing_', '')
                  .replace('[', '')
                  .replace(']', '')
                  .substring(0, 3)
              )
            }
          }
        })

        // TODO PY05032 - Remove this jQuery when we have a better solution
        if (!$(fieldInstance.element).parsley().isValid() && !fieldInstance.validationResult) {
          const field_error_name_uniq = [...new Set(field_error_name)].sort()

          error_form_event(
            fieldInstance.element.dataset.formName,
            'error form',
            field_error_name_uniq.join('-'),
            fieldInstance.element
          )
        }
      }
      fieldInstance.element.classList.remove('validate-form')
    })
  } catch (error) {}

  document
    .querySelectorAll('form.utag-form:not(.mice-quote-form):not(.ibepro-utag-register):not(.staragent-utag-register)')
    .forEach(function (form) {
      form.querySelectorAll('input, select, textarea').forEach(function (input) {
        if (isVisible(input)) {
          const parentForm = input.closest('form')
          const form_name = parentForm.dataset.formName
          const custom_field_name = input.dataset.utagNormalizeFieldName

          switch (input.type) {
            case 'select-multiple':
            case 'select-one':
              input.addEventListener('click', function () {
                start_form_event(
                  form_name,
                  'start form',
                  getFieldName(custom_field_name, normalizeFieldName(input.name)),
                  parentForm
                )
                parentForm.classList.add('form-sent')
              })
              break
            case 'text':
            case 'textarea':
            case 'checkbox':
            case 'file':
            case 'radio':
            case 'email':
            case 'password':
            case 'submit':
              if (
                input.type === 'submit' &&
                !(
                  form_name === 'collect points' ||
                  form_name === 'points statement' ||
                  form_name === 'transfer points' ||
                  form_name === 'authorize new registrations'
                )
              ) {
                break
              }
              input.addEventListener('click', function () {
                start_form_event(
                  form_name,
                  'start form',
                  getFieldName(custom_field_name, normalizeFieldName(input.name)),
                  parentForm
                )
                parentForm.classList.add('form-sent')
              })
              break
          }
        }
      })
    })

  if (document.querySelector('#is_new_register')) {
    if (document.querySelector('#is_new_register').value === 'true') {
      sendUtagEvent({
        data: {
          event_name: 'success_form',
          event_cat: 'form',
          event_act: 'success form-lead form',
          event_lbl: '',
          event_purpose: 'loyalty',
          event_structure: 'impression',
        },
      })
    } else {
      sendUtagEvent({
        data: {
          event_name: 'success_form',
          event_cat: 'form',
          event_act: 'success form-login form',
          event_lbl: '',
          event_purpose: 'loyalty',
          event_structure: 'impression',
        },
      })
    }
  }

  document.addEventListener('DOMContentLoaded', function () {
    document.addEventListener('submit', function (event) {
      if (event.target.matches('.login form.utag-form')) {
        sendUtagEvent({
          data: {
            event_name: 'success_form',
            event_cat: 'form',
            event_act: 'success form-login form',
            event_lbl: '',
            event_purpose: 'loyalty',
            event_structure: 'impression',
          },
        })
      }
    })

    var savePasswordElement = document.querySelector('#save_password')
    savePasswordElement?.addEventListener(
      'change',
      function () {
        sendUtagEvent({
          data: {
            event_name: 'save_password',
            event_cat: 'login',
            event_act: 'save password',
            event_lbl: '',
            event_purpose: 'information',
            event_structure: 'body',
          },
        })
      },
      { once: true }
    )
  })
}

if (typeof IB === 'undefined') {
  window.IB = {}
}

if (IB.formUtagEvents === undefined) {
  window.IB.formUtagEvents = {
    init: initializeUtagEvents,
  }
}
