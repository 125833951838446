import { REGEX_LIB } from './constants'

/**
 * Validates a phone number checking it has only numbers and its length
 * @param {String} value - Phone value
 *
 * Function that validates if mobile phone has 5 digits, ignoring spaces
 */
export const validatePhone = value => REGEX_LIB.phoneNumber.test(value)

/**
 * Validates email format
 * @param {String} email - email
 */
export const validateEmail = email => REGEX_LIB.emailFormat.test(email)

/**
 * Validates if a string has no spaces
 * @param {*} value
 * @returns
 */
export const validateNoSpaces = value => REGEX_LIB.noSpaces.test(value)
