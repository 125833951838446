

/**
   * Init form validation
   *
   * @param {object} $form - form to validate
   * @param {boolean} onlyVisible - if true validates only visible
   */
function initValidation($form, onlyVisible) {
  // Form validation init
  let $required_fields = $form.find('[data-parsley-required]:not([data-parsley-errors-container])');

  FORMZ.required_fields.init($required_fields);

  let parsley_config = {
    errorsContainer: function (pe) {
      let $err = pe.$element.closest('.fz-col').find('.fz-error-container');
      return $err;
    }
  };

  if (onlyVisible) {
    parsley_config.excluded = 'input:hidden, select:hidden';
  }

  $form.parsley(parsley_config);
}

window.IB.forms = {
  initValidation: initValidation
};
