import './formz.scss'
import './parsley.min'
import './parsley_password'
import './parsley_custom_validators'

// PY04776-648 - 774241
function importDinamicTextError(language) {
  if (language) {
    import(`./../../../../assets/javascripts/i18n/${language}`)
  } else {
    // Default language
    import(`./../../../../assets/javascripts/i18n/es`)
  }
}

function initFormz($required_fields) {
  $required_fields.each(function (idx, el) {
    /* Add error containers */
    $(el).closest('.fz-col').append($('<div class="fz-error-container">'))

    // configuration of the observer
    // If class attribute of any of the required fields is modified
    // Oberserver triggers
    const config = { attributes: true }
    const observer = new MutationObserver(function (mutations) {
      mutations.forEach(function (mutation) {
        const class_attr = mutation.target.getAttribute('class')
        const $fz_field = $(mutation.target).closest('.fz-field')
        if ($fz_field.length && typeof class_attr === 'string' && class_attr !== '' && class_attr.indexOf('parsley-error') !== -1) {
          $fz_field.addClass('parsley-error')
        } else {
          $fz_field.removeClass('parsley-error')
        }
      })
    })
    // Start observer on element
    observer.observe(el, config)
  })
}

function load() {
  const $formz = $('.formz')
  if (!$formz.length) {
    return
  }
  const language = document.documentElement.getAttribute('data-js-lang')
  importDinamicTextError(language)

  const $required_fields = $formz.find('[data-parsley-required]:not([data-parsley-errors-container]), [data-parsley-validate]:not([data-parsley-errors-container])')

  FORMZ.required_fields.init($required_fields)
  /* Errors will be dsiplayed on nearest fz-error-container */
  const parsley_config = {
    errorsContainer(pe) {
      const $err = pe.$element.closest('.fz-col').find('.fz-error-container')
      return $err
    },
    errorsWrapper: '<div class="parsley-errors-list"></div>',
    errorTemplate: '<div class="parsley-errors-error"></div>',
  }

  $formz.parsley(parsley_config)
}

window.FORMZ = {}
window.FORMZ.required_fields = {
  init: initFormz,
  load,
}

FORMZ.required_fields.load()

$(document)
  .on('change', '.formz input[type="file"]', function (e) {
    const $el = $(this)
    const fn = $el.val()
    $el.siblings('input[type="text"]').val(fn)
  })
  .on('focus', '.formz input:text, .formz input:password, .formz input[type="email"], .formz select', function (e) {
    const $el = $(this)
    $el.closest('.fz-field').addClass('fz-focus')
  })
  .on('blur', '.formz input:text, .formz input:password, .formz input[type="email"], .formz select', function (e) {
    const $el = $(this)
    $el.closest('.fz-field').removeClass('fz-focus')
  })
  .on('click touchstart', '.formz .fz-collapsable', function (e) {
    const $el = $(this)
    const $content = $el.next('.fz-collapsable-content')
    if ($content.length) {
      $el.add($content).toggleClass('fz-collapsed')
    }
  })

// Esta función se llama desde el html para validar el captcha, tiene
// que estar fuera del ready para que la encuentre.
window.recaptchaCallback = function () {
  $('#captchafield').val('nonEmpty').next('.fz-error-container').html('')
}
