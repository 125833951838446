import './market_simulator.scss'


$(function () {
  let $simulator = $('#market-simulator');
  $simulator.on('mousenter', '.title', function () {
    $simulator.addClass('opened');
  });
  $simulator.on('click', '.title', function () {
    $simulator.toggleClass('opened');
  });
  $simulator.on('click', '.close', function () {
    $simulator.removeClass('opened');
  });
});
