import './forms_validation'
import './reservation_management_form'

import { initializeUtagEvents } from './forms.analytics'

$(function () {
  $(document)
    .on('focus', '.input-text input[type="text"], .input-text input[type="email"]', function (event) {
      $(this).closest('.input-text').addClass('focused')
    })
    .on('blur', '.input-text input[type="text"], .input-text input[type="email"]', function (event) {
      $(this).closest('.input-text').removeClass('focused')
    })

  $(document).on('keyup', '.password-row input.password', function () {
    const $el = $(this)
    if ($el.hasClass('parsley-success')) {
      $el.closest('.password-row').find('.validation-errors').addClass('hidden')
      $el.closest('.password-row').find('.validation-success').removeClass('hidden')
    } else {
      $el.closest('.password-row').find('.validation-errors').removeClass('hidden')
      $el.closest('.password-row').find('.validation-success').addClass('hidden')
    }
  })
})

// init utag events
initializeUtagEvents()
