import { showWelcomeModalIHG } from '../ihg_modal/ihg_modal'

const IGH_BUTTON_SELECTOR = '.js-ihg-modal-button'
const IHG_MODAL_ID = 'js-modal-bottom-ihg'

function init() {
  const buttons = document.querySelectorAll(IGH_BUTTON_SELECTOR)

  buttons.forEach(button => {
    button.addEventListener('click', () => {
      showWelcomeModalIHG(IHG_MODAL_ID)
    })
  })
}

init()
